<template>
	<ShutterPanel />
</template>


<script type="text/javascript">
    import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterContract from '@/mixins/shutters-manager/Contract.js'

	export default {
        name: "ContractShutter",
        mixins: [Shutter, ShutterContract],
        props: {
            contract_id: { type: Number, default: 0 },
            from: { type: String, default: '' },
            mare_id: { type: Number, default: 0 }
        },
		data () {
			return {
			}
        },
        created() {
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.setupContractForm(this.contract_id, this.from, this.mare_id)
            },
        },
        components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            ShutterPanel
		},
    }
</script>
